@import "../../../../../public/mixins/mixins.scss";

.packageContainer {
  padding: 12px;
  border-radius: 20px;
  box-shadow: 0 3px 8px 6px #f4f4f4;
  margin: 0 10px;
  min-width: 355px;
  min-height: 355px;
  margin-top: 10px;
  margin-bottom: 10px;

  @media (max-width: 465px) {
    min-width: 315px;
  }

  @media (max-width: 420px) {
    min-width: 265px;
  }

  .packageImgContainer {
    width: 100%;
    height: 160px;
    position: relative;
    border-radius: 12px;
    overflow: hidden;

    .journey {
      position: absolute;
      background-color: #276842;
      //276842
      bottom: 30px;
      padding: 5px 17px 5px 7px;
      border-radius: 0px 20px 20px 0px;
      @include flexContainer(row, space-between, center);
      @include font(12px, 600, #fff);
      display: flex;
      gap: 14px;
      align-items: center;
      justify-content: center;
    }
  }

  .packageInfoContainer {
    @include flexContainer(column, unset, unset, 10px);
    padding: 15px;

    .packageName {
      @include font(14px, 600, #4a536a, 0.56px);
    }

    .durationsContainer {
      @include flexContainer(row, space-between, unset);
    }

    .heading {
      @include font(14px, 600, #333, 0.44px);
      margin-bottom: 0px;
    }

    .subHeading {
      @include font(14px, 500, #333, 0.44px);
    }

    .devider {
      background: #e4e8ff;
      height: 1px;
      width: 100%;
    }

    .packageCost {
      @include font(14px, 500, #333, 0.52px);
      .highlight {
        @include font(16px, 600, #006daf, 0.64px);
      }
    }
  }
}
